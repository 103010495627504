export enum LocaleEnum {
  en = 'en',
  ko = 'ko',
}

export enum KoreanEnum {
  'ko-kr' = 'ko-kr',
  'ko' = 'ko',
  'kr' = 'kr',
}

export type Locale = {
  en: string
  ko: string
}
