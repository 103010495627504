import * as React from 'react'
import Head from 'next/head'
import { getI18n } from 'react-i18next'

import { LocaleEnum } from '@/types/locale'

// meta 태그는 _app head로 들어가서 페이지마다 다른 메타가 있을 경우 교체
// _document에 들어갈 경우 교체되지 않고 중복 작성되어 문제가 발생할 여지 있음
export default function HtmlHeadMetas() {
  const t = React.useCallback(
    (key: string) => getI18n().getResource(getI18n().language, 'header', key),
    [],
  )
  return (
    <Head>
      <title key="title">{t('title')}</title>
      <meta charSet="utf-8" />
      <link
        rel="alternate"
        hrefLang={LocaleEnum.en}
        href={`${process.env.NEXT_PUBLIC_THE_RICH_DOMAIN_URL}/en`}
      />
      <link
        rel="alternate"
        hrefLang={LocaleEnum.ko}
        href={process.env.NEXT_PUBLIC_THE_RICH_DOMAIN_URL}
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="title" content={t('title')} />
      <meta name="description" content={t('description')} />
      <meta
        name="keywords"
        content="web, software, mobile, app, 더리치, therich, 주식, 포트폴리오, 배당, 자산"
      />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta name="apple-mobile-web-app-title" content="TheRich" />
      <meta name="theme-color" content="#f1b13c" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="author" content="TheRich" />
      <meta name="image" content="https://images.therich.io/ogtag/home.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/icon.png"></link>

      {/* main og */}
      <meta key="og:url" property="og:url" content="https://www.therich.io/home" />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content="더 리치 - The Rich." />
      <meta key="og:description" property="og:description" content={t('catchphrase')} />
      <meta key="og:image" property="og:image" content="https://images.therich.io/ogtag/home.png" />
      <meta key="og:image:width" property="og:image:width" content="400" />
      <meta key="og:image:height" property="og:image:height" content="400" />

      {/* 트위터 */}
      <meta key="twitter:url" name="twitter:url" content="https://www.therich.io/home" />
      <meta key="twitter:type" name="twitter:type" content="website" />
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta key="twitter:title" name="twitter:title" content="The Rich.- 더 리치" />
      <meta key="twitter:description" name="twitter:description" content={t('catchphrase')} />
      <meta
        key="twitter:image"
        name="twitter:image"
        content="https://images.therich.io/ogtag/home.png"
      />
      <meta key="twitter:image" name="twitter:image:width" content="400" />
      <meta key="twitter:image" name="twitter:image:height" content="400" />

      {/* naver */}
      <meta name="naver-site-verification" content="b8d12e71d993e45bcfef9158bb131dac9d92a4e3" />

      {/* facebook */}
      <meta property="fb:app_id" content="234758025176691" />

      <script
        key="ld+json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "더리치(TheRich)",
            "url": "https://www.therich.io",
            "logo": "https://images.therich.io/ogtag/home.png",
            "sameAs": [
                "https://www.facebook.com/TheRichTeam",
                "https://itunes.apple.com/kr/app/id1462844342",
                "https://play.google.com/store/apps/details?id=io.therich.app",
                "https://blog.naver.com/therichio",
                "https://twitter.com/The_Rich_App",
                "https://www.instagram.com/billionaires_the_rich"
            ]
        }`,
        }}
      />
    </Head>
  )
}
